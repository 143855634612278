/* stylelint-disable selector-class-pattern */
.container-dnd {
    min-width: 100%;
    height: 560px;
    border-radius: 5px;
    border: 3px dashed bisque;
    padding: 1em;
    transition: all 0.3s ease;
    background-color: rgb(225 225 225 / 30%);
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.label-dnd {
    transition: all 0.3s ease;
    font-weight: bold;
    color: black !important;
    font-size: 1.1em;
    transform: scale(0.9);
}

.isDragging,
.container-dnd:hover {
    cursor: pointer;
}

/* CONTAINER DRAG & DROP WHIT IMAGE  */
.image-selected {
    object-fit: contain;
    vertical-align: top;
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

/* BUTTONS IMAGE SELECTED */
.container-buttons {
    position: absolute;
    padding: 10px;
    margin: 10px;
    min-height: 40px;
    background-color: rgb(200 200 200 / 50%);
    border-radius: 5px;
    border: 1px solid white;
}

.container-buttons > button {
    all: unset;
    border: 1px solid #ccc;
    padding: 8px 10px;
    flex: 1;
    cursor: pointer;
    font-weight: bold;
    transition: all 0.2s ease-in-out;
    color: black;
    background-color: rgb(200 200 200 / 50%);
    border-radius: 5px;
    min-width: 50px;
    align-content: center;
    margin: 4px;
    box-shadow: 2px 2px #666;
    text-shadow: 1px 1px #fff;
}

.loading-label {
    font-weight: bold;
    animation: loading 0.5s infinite alternate;
}

@keyframes loading {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.container-buttons > button:hover {
    background-color: rgb(143 143 150);
}

/* SUCCESS UPLOAD IMAGE */
.url-cloudinary-sumbit {
    position: absolute;
    top: 25px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.1em;
    animation: fadeIn 0.5s ease-in;
}

.url-cloudinary-sumbit > a {
    all: unset;
    background-color: slateblue;
    padding: 0.5em;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    margin: 0 0 0 1em;
}

.url-cloudinary-sumbit > a:hover {
    background-color: rgb(77 64 167);
}

.url-cloudinary-sumbit > a:active {
    background-color: slateblue;
}

.image-prev {
    position: absolute;
    display: inline-block !important;
    width: 100%;
    height: 100%;
}

.image-prev img {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: inherit;
    margin: auto;
    object-fit: cover;
}

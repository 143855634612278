@import 'https://fonts.googleapis.com/css2?family=Fira+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap';

/* stylelint-disable font-family-no-missing-generic-family-keyword */
@font-face {
    font-family: icomoon;
    src: url('./ico-fonts/icomoon.eot?i2ar2o');
    src: url('./ico-fonts/icomoon.eot?i2ar2o#iefix') format('embedded-opentype'),
        url('./ico-fonts/icomoon.ttf?i2ar2o') format('truetype'), url('./ico-fonts/icomoon.woff?i2ar2o') format('woff'),
        url('./ico-fonts/icomoon.svg?i2ar2o#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: icomoon !important;
    speak-as: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-like-verde::before {
    content: '\e90f';
    color: #509f2c;
}

.icon-like-blanco::before {
    content: '\e910';
    color: #fff;
}

.icon-mas-verde::before {
    content: '\e911';
    color: #509f2c;
}

.icon-mas-blanco::before {
    content: '\e912';
    color: #fff;
}

.icon-mas-activo-verde::before {
    content: '\e913';
    color: #509f2c;
}

.icon-mas-activo-blanco::before {
    content: '\e914';
    color: #fff;
}

.icon-facil-blanco::before {
    content: '\e900';
    color: #fff;
}

.icon-facil-verde::before {
    content: '\e903';
    color: #509f2c;
}

.icon-medio-blanco::before {
    content: '\e901';
    color: #fff;
}

.icon-medio-verde::before {
    content: '\e904';
    color: #509f2c;
}

.icon-dificil-blanco::before {
    content: '\e902';
    color: #fff;
}

.icon-dificil-verde::before {
    content: '\e905';
    color: #509f2c;
}

.icon-tiempo-chef-verde::before {
    content: '\e906';
    color: #509f2c;
}

.icon-tiempo-chef-blanco::before {
    content: '\e907';
    color: #fff;
}

.icon-tiempo-verde::before {
    content: '\e908';
    color: #509f2c;
}

.icon-tiempo-blanco::before {
    content: '\e909';
    color: #fff;
}

.icon-porcion-verde::before {
    content: '\e90a';
    color: #509f2c;
}

.icon-porcion-blanco::before {
    content: '\e90b';
    color: #fff;
}

.icon-estrella-activo::before {
    content: '\e90c';
    color: #fff;
}

.icon-estrella-2::before {
    content: '\e90d';
    color: #9f9d2c;
}

.icon-estrella::before {
    content: '\e90e';
    color: #fefefe;
}

.icon-estrella-gris-activo::before {
    content: '\e90c';
    color: #606062;
}

.icon-estrella-gris::before {
    content: '\e90e';
    color: #606062;
}

.icon-estrella-amarilla-activo::before {
    content: '\e90c';
    color: #509f2c;
    text-shadow: 1px 1px 1px #555;
    font-size: 2rem;
    cursor: pointer;
}

.icon-estrella-amarilla::before {
    font-size: 2rem;
    content: '\e90e';
    color: #509f2c;
    cursor: pointer;
}

/* stylelint-disable block-no-empty */

/* 1460
 
------------------------------------------------------------------------- */
@media screen and (max-width: 1460px) {
}

/* ---------------------------------------------------------------------- */

/* 1380
 
------------------------------------------------------------------------- */
@media screen and (max-width: 1380px) {
}

/* ---------------------------------------------------------------------- */

/* 1180
 
------------------------------------------------------------------------- */

@media screen and (max-width: 1180px) {
    .edit-page .mod-preparacion .cont .sec-paso {
        display: block;
    }

    .edit-page .mod-preparacion .cont .sec-paso .controles {
        position: absolute;
        width: 115px;
        height: 25px;
        top: 0;
        right: 0 !important;
        margin-top: 0 !important;
    }
    .edit-page .mod-preparacion .cont .sec-paso .controles button {
        height: 24px;
    }

    .edit-page .mod-preparacion .cont .sec-paso .operaciones {
        width: 100% !important;
        margin-top: 10px;
    }
}

/* ---------------------------------------------------------------------- */

/* 1025
 
------------------------------------------------------------------------- */

@media screen and (max-width: 1025px) {
}

/* ---------------------------------------------------------------------- */

/* 900
 
------------------------------------------------------------------------- */
@media screen and (max-width: 900px) {
    .edit-page {
        .cyg-col-left,
        .cyg-col-right {
            width: 100% !important;
            padding-right: 0 !important;
        }
        .cyg-col-right .mod-i {
            position: relative;
            width: 100%;
            float: left;
            margin-top: 60px;
        }

        /* btn enviar */
        .btn.btn-enviar.pc {
            display: none;
        }
        .btn.btn-enviar.movil {
            display: block;
            margin-top: 30px !important;
        }
    }

    .editing .as-scroll {
        height: auto !important;
        max-height: inherit !important;
    }
}

/* ---------------------------------------------------------------------- */

/* 768
 
------------------------------------------------------------------------- */
@media screen and (max-width: 768px) {
    .edit-page {
        .cyg-col-right .cont p input {
            padding: 10px !important;
            font-size: 1rem !important;
        }
        .cyg-col-right .mod-i {
            padding: 0 20px;
        }

        .step-info .row-step .col {
            width: 49.9998%;
        }

        .i-box {
            display: block !important;
        }
        .i-box > div {
            width: 48%;
            min-height: 90px;
            float: left;
            margin: 0 !important;
            padding: 0 5px !important;
        }
    }
}

/* @media screen and (max-width: 645px) and (orientation: landscape) {
}
 */

/* ---------------------------------------------------------------------- */

/* 540
 
------------------------------------------------------------------------- */
@media screen and (max-width: 540px) {
    .edit-page {
        .container-dnd span {
            padding-top: 90px;
        }
        .container-dnd span::before {
            width: 60px;
            height: 60px;
        }
    }

    .edit-page .mod-preparacion .cont .sec-paso .operaciones {
        display: block !important;
    }
    .edit-page .mod-preparacion .cont .sec-paso .operaciones .fld {
        width: 48% !important;
        float: left;
        margin-bottom: 10px;
        margin-right: 2% !important;
    }

    .edit-page .mod-preparacion .cont .sec-paso .operaciones .fld-content {
        padding: 10px !important;
    }
    .edit-page .mod-preparacion .cont .sec-paso .operaciones .fld-addon {
        right: 10px;
    }
}

/* ---------------------------------------------------------------------- */

/* 430
 
------------------------------------------------------------------------- */
@media screen and (max-width: 430px) {
    .edit-page {
        .row-nutri {
            display: block !important;
            padding-bottom: 5px;
            margin-bottom: 5px;
            border-bottom: 1px solid var(--color-ddd);
        }

        .row-nutri .col-1 {
            width: 100% !important;
            min-width: inherit !important;
            max-width: inherit !important;
            padding-bottom: 0;
        }
    }
}

/* ---------------------------------------------------------------------- */

/* 430
 
------------------------------------------------------------------------- */
@media screen and (max-width: 375px) {
}

.p-6 {
	padding: 1.5rem;
	margin-bottom: 3rem;
}

.max-w-2xl {
	max-width: 42rem;
}

.mx-auto {
	margin-left: auto;
	margin-right: auto;
}

.bg-white {
	background-color: white;
}

.rounded-2xl {
	border-radius: 1rem;
}

.shadow-lg {
	box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.text-xl {
	font-size: 1.25rem;
	font-weight: bold;
}

.font-bold {
	font-weight: bold;
}

.mb-4 {
	margin-bottom: 1rem;
}

.list-disc {
	list-style-type: disc;
}

.list-inside {
	list-style-position: inside;
}

.text-blue-600 {
	color: #2563eb;
}

.underline {
	text-decoration: underline;
}

.bg-gray-100 {
	background-color: #f3f4f6;
}

.p-4 {
	padding: 1rem;
}

.rounded-lg {
	border-radius: 0.5rem;
}

.overflow-auto {
	overflow: auto;
}

.my-4 {
	margin-top: 1rem;
	margin-bottom: 1rem;
}

strong {
	font-weight: bold;
}

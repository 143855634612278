.input-header {
    width: 10rem;
    height: 3rem;
    border: none;
}

.input-header-wrapper {
    position: fixed;
    top: 6rem;
    right: 20rem;
    border-radius: 1rem;
    box-shadow: 0 0 5px 0 rgb(92 89 89 / 75%);
}

.input-header-btn {
    width: 4rem;
    height: 3rem;
    border-radius: 1rem 0 0 1rem;
    border-style: none;
    cursor: pointer;
}

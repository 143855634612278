$background-color: #fff;

.input-autocomplete {
	display: flex !important;
	flex-direction: column;
	border-radius: 7px;
	position: relative;
	transition: background 0.3s ease;
	height: auto !important;
	border: 1px solid var(--color-verde);
	border-radius: var(--radius-8);
	background-color: $background-color !important;

	.form-control-auto {
		background-color: $background-color !important;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		border-radius: 5px;
		width: 100%;
		height: auto !important;
		border-radius: var(--radius-8);

		input {
			border: 0;
			border-radius: 5px;
			width: 100%;
			padding: 15px 20px;
			outline: none;
			min-height: 40px;
			border-radius: var(--radius-8);
		}

		label {
			font-size: 10px;
			text-transform: uppercase;
			color: #949494;
			padding: 10px 0px 10px 20px;
		}

		.btn-clear,
		.btn-back {
			display: inline-flex;
			align-items: center;
			width: 40px;
			height: 40px;
			padding: 0 12px;
			cursor: pointer;
		}

		.btn-clear {
			border-left: 1px solid var(--color-verde);

			&::after {
				content: '';
				background-image: url('../../images/svg/ico-cerrar.svg');
				background-repeat: no-repeat;
				background-size: contain;
				width: 15px;
				height: 15px;
				display: block;
				margin: auto;
			}
		}

		.btn-back {
			border-right: 1px solid var(--color-verde);
			display: none;

			&::after {
				content: '';
				background-image: url('../../images/svg/ico-flecha-izquierda.svg');
				background-repeat: no-repeat;
				background-size: contain;
				width: 15px;
				height: 15px;
				display: block;
				margin: auto;
			}
		}
	}

	.suggestions {
		position: absolute;
		display: block !important;
		top: 48px;
		left: 0px;
		z-index: 999;
		cursor: pointer;
		width: 100%;
		height: auto !important;
		// overflow: auto;
		border: 1px solid var(--color-verde);
		border-radius: var(--radius-8);
		background-color: $background-color !important;

		.suggestion-item {
			display: flex !important;
			flex-direction: row;
			justify-content: flex-start !important;
			padding: 5px 0;
			height: auto !important;
			border-bottom: 2px solid #fff;
			transition: all 0.1s ease;
			border-radius: var(--radius-8);

			span {
				padding: 0 20px;
				width: 100%;
			}

			&:hover {
				background-color: #dbdbdb !important;
				color: #000000;
			}

			.ico-search {
				display: inline-flex;
				align-items: center;
				width: 30px;
				height: 30px;
				padding-left: 12px;
				margin-right: 12px;

				&::after {
					content: '';
					background-image: url('../../images/svg/ico-lupa.svg');
					background-repeat: no-repeat;
					background-size: contain;
					width: 15px;
					height: 15px;
					display: block;
					margin: auto;
				}
			}
		}
	}

	.suggestions > div,
	.form-control-auto input {
		font-size: 14px !important;
		background-color: $background-color !important;
		color: #000;
	}
}

@media screen and (max-width: 768px) {
	.btn-back {
		display: inline-flex !important;
	}
}

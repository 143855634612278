.toggle-button {
	padding: 5px;
	border-radius: 20px;
	background-color: #DBE8B7;
	cursor: pointer;
	height: fit-content;
	margin-right: 10px;
	margin-top: 5px;
	margin-bottom: 5px;
}

.option {
	width: 75px;
	padding: 5px 10px;
	border-radius: 18px;
	justify-content: center;
	color: black;
	background-color: #DBE8B7;
}

.selected {
	color: white;
	background-color: #509F2C;
}

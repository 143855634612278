/* stylelint-disable selector-class-pattern */

/* FIX OCULTAR BOTÓN MENÚ */
@media screen and (max-width: 2400px) and (min-width: 1141px) {
    .btn-menu {
        display: none !important;
    }
}

:root {
    /* colores */
    --color-verde: #509f2c;
    --color-verde-rgba-1: rgb(178 207 103 / 47%);
    --color-verde-rgba-2: rgb(219 232 183 / 29%);
    --color-gris: #6c6b6c;
    --color-000: #000;
    --color-fff: #fff;
    --color-f5f: #f5f5f5;
    --color-ddd: #ddd;
    --color-eee: #eee;
    --color-ccc: #ccc;
    --color-999: #999;
    --color-666: #666;
    --color-333: #333;
    --color-placeholder: var(--color-gris);

    /* font */
    --font: 'Fira Sans', sans-serif;

    /* medidas */
    --w-filtro: 400px;
    --h-footer: 155px;

    /* height */
    --h-header: 100px;
    --h-header-scroll: 50px;
    --h-buscador: 150px;
    --h-buscador-scroll: 70px;
    --top-main: var(--h-header);
    --top-main-buscador: calc(var(--h-header) + var(--h-buscador));
    --top-main-buscador-movil: calc(var(--h-header-scroll) + var(--h-buscador));

    /* radius */
    --radius-8: 8px;
    --radius-12: 12px;
}

/* ---------------------------------------------------------------------- */

/* 1460
 
------------------------------------------------------------------------- */
@media screen and (max-width: 1460px) {
    /* intro */
    #intro .bg-textura {
        height: 660px;
    }

    #intro .bg-textura::after {
        height: 140px;
    }

    .ichef--cate span {
        font-size: 0.8rem;
    }
}

/* ---------------------------------------------------------------------- */

/* 1380
 
------------------------------------------------------------------------- */
@media screen and (max-width: 1380px) {
    /* root */
    :root {
        /* height */
        --h-header: 80px;
        --h-header-scroll: 50px;
        --h-buscador: 120px;
        --h-buscador-scroll: 60px;
    }

    /* #TEXTO's */
    body {
        font-size: 0.95rem;
    }
    h1 {
        font-size: 2.1rem;
    }
    h2 {
        font-size: 2rem;
    }
    h3 {
        font-size: 1.6rem;
    }
    h4 {
        font-size: 1.3rem;
    }
    h5 {
        font-size: 1.1rem;
    }
    h6 {
        font-size: 0.95rem;
    }

    /* cointainer */
    .container-3 {
        max-width: 1200px !important;
    }

    /* # HEADER */
    #logo img {
        height: 55px;
    }

    /* # MAIN */

    /* #filtros */
    .item-aco {
        font-size: 0.95rem;
    }

    /* intro */
    #intro .bg-textura {
        height: 600px;
    }

    #intro .bg-textura::after {
        height: 120px;
    }

    /* #footer */
    #footer {
        padding: 40px 0;
    }

    /* EDITAR */
}

/* ---------------------------------------------------------------------- */

/* 1180
 
------------------------------------------------------------------------- */

@media screen and (max-width: 1180px) {
    /* buscador */
    #buscador form,
    #buscador .cmyNjn {
        width: 450px;
    }

    /* grilla */
    .grilla .cont-receta {
        flex: 0 0 25%;
        max-width: 25%;
    }

    /* intro */
    #intro {
        margin-bottom: 50px;
    }
    #intro .bg-textura {
        height: 520px;
    }

    #intro .bg-textura::after {
        height: 100px;
    }

    #intro .cyg-col-right .cont {
        padding: 20px;
    }

    /* iconos */
    .mod-iconos {
        padding: 0 20px;
    }

    .mod-iconos i,
    .mod-iconos svg {
        height: 40px;
    }

    .facil svg {
        width: 38px;
    }

    .mod-iconos span {
        font-size: 0.75rem;
    }

    /* EDITAR */

    /* .edit-page #intro {
        padding-top: 0;
    } */
}

/* ---------------------------------------------------------------------- */

/* 1025
 
------------------------------------------------------------------------- */

@media screen and (max-width: 1025px) {
    :root {
        /* medidas */
        --w-filtro: 400px;
        --h-footer: 135px;
        --h-header: 80px;
        --h-header-scroll: 50px;
        --h-buscador: 80px;
        --h-buscador-scroll: 60px;
    }

    /* #TEXTO's */
    h1 {
        font-size: 2rem;
    }
    h2 {
        font-size: 1.7rem;
    }
    h3 {
        font-size: 1.4em;
    }
    h4 {
        font-size: 1.2rem;
    }
    h5 {
        font-size: 1rem;
    }
    h6 {
        font-size: 0.9rem;
    }

    body {
        font-size: 0.9rem;
    }

    /* #main */
    #main {
        padding-bottom: 40px;
    }

    /* buscador */
    #buscador form,
    #buscador .cmyNjn {
        width: 350px;
    }

    /* btn filtro */

    /* tit */
    .tit {
        margin-bottom: 15px;
    }

    /* mod */
    .mod {
        margin-bottom: 30px;
        padding-bottom: 30px;
    }

    /* preparacion */
    .mod-preparacion section {
        margin-bottom: 20px;
    }

    .ichef--cont-cate {
        justify-content: center;
    }

    .ichef--cate {
        min-width: 80px;
        margin-top: 10px;
    }
}

/* ---------------------------------------------------------------------- */

/* 900
 
------------------------------------------------------------------------- */
@media screen and (max-width: 900px) {
    :root {
        /* medidas */
        --h-footer: 0;

        /* counter-reset: link; */
    }

    /* #TEXTO's */
    h1 {
        font-size: 1.95rem;
    }
    h2 {
        font-size: 1.6rem;
    }
    h3 {
        font-size: 1.3rem;
    }
    h4 {
        font-size: 1.1rem;
    }

    /* menú */
    .menu {
        margin-right: 30px;
    }
    .menu a {
        margin-left: 20px;
    }

    /* btn buscar */
    #btn-buscar {
        margin-right: 0px;
    }

    /* receta */
    .receta.gr {
        display: none !important;
        visibility: hidden;
    }

    /* grilla */
    .grilla .cont-receta {
        flex: 0 0 25%;
        max-width: 25%;
    }

    /* #intro */
    #intro {
        padding-top: 40px;
    }

    #intro .cyg-row {
        display: block !important;
    }

    #intro .cyg-col-left {
        width: 100%;
        padding: 0 7px;
    }

    #intro .cyg-col-right {
        width: 100%;
    }
    #intro .cyg-col-left::after {
        display: none;
    }
    #intro .cyg-col-right::before {
        right: 0;
        margin: auto;
        width: calc(100% - 14px);
    }
    #intro .cyg-col-right::after {
        width: calc(100% - 14px);
    }

    #intro .container {
        position: relative;
    }

    #intro .bg-textura {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
    }

    /* mod */
    .mod {
        margin-bottom: 20px;
        padding-bottom: 20px;
    }

    /* preparacion */
    .mod-preparacion p {
        padding-right: 0;
    }

    /* mod ingredientes - valores */
    .mod-ingredientes,
    .mod-valores {
        padding: 30px;
        margin-bottom: 30px;
    }

    /* #filtros */
    #filtros {
        padding: 30px !important;
    }

    /* #footer */
    #footer {
        position: relative;
        padding: 30px 0 20px;
    }

    #footer .cyg-col-full {
        display: block;
    }

    /* redes */
    #footer .mod-redes {
        text-align: center;
        width: 100% !important;
        float: left;
        position: relative;
        margin-bottom: 30px;
    }

    /* address */
    #footer address {
        width: 100%;
        max-width: inherit;
        text-align: center;
        margin-bottom: 30px;
    }

    #footer address br {
        display: block;
    }

    #footer address a {
        float: none;
        margin: 0 0 15px;
    }

    /* nav */
    #footer nav {
        max-width: inherit;
        display: block;
        text-align: center;
    }

    #footer nav div {
        text-align: center;
    }

    #footer nav a {
        display: block;
        margin-bottom: 15px !important;
    }

    /* iconos */
    .mod-iconos {
        position: relative;
        bottom: auto;
        padding: 0;
        display: block;
        margin-top: 30px;
    }

    .mod-iconos div {
        margin-right: 35px;
    }

    .mod-iconos i,
    .mod-iconos svg {
        height: 40px;
    }

    .facil svg {
        width: 38px;
    }

    .mod-iconos span {
        font-size: 0.75rem;
    }
}

/* ---------------------------------------------------------------------- */

/* 768
 
------------------------------------------------------------------------- */
@media screen and (max-width: 768px) {
    :root {
        /* medidas */
        --w-filtro: 360px;
        --h-header: 50px;
        --h-header-scroll: 50px;
        --h-buscador: 60px;
        --h-buscador-scroll: 50px;
    }

    /* container */
    .container {
        padding: 0 14px;
    }

    /* # HEADER */
    #header,
    #header .container,
    #header .cyg-row,
    #header .cyg-col-full,
    #header .cyg-row>div {
        height: var(--h-header-scroll);
    }

    .btn-buscar-wrapper {
        flex: 1;
        justify-content: center;
    }

    #header .autocomplete-input > div .wrapper {
        top: 3px;
        height: 45px;
    }

    /* logo */
    #logo img {
        height: 35px;
    }

    /* btn buscar */
    #btn-buscar {
        margin-right: 0px;
        margin-left: 10px;
    }

    /* btn sesion */
    #btn-sesion {
        display: none;
    }
    .menu #btn-sesion {
        display: flex !important;
        float: none;
        margin: auto;
    }

    .sesion-activa .menu #btn-sesion {
        display: block !important;
        margin-top: 10px;
    }

    .sesion-activa .hola {
        display: inline-block;
        float: none;
    }
    .sesion-activa .hola::before {
        display: none;
    }

    .selectors {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .folder-scroll {
        display: flex;
        flex-wrap: nowrap !important;
        overflow-x: scroll;
    }

    .selector {
        width: 170px;
        margin-bottom: 10px;
    }

    .sesion-activa .menu #btn-sesion div {
        float: none;
        display: inline-block;
    }
    .sesion-activa #btn-sesion div {
        height: auto !important;
    }
    .sesion-activa #btn-sesion div::after {
        display: none;
    }

    /* sub menu */
    .sub-menu {
        position: relative;
        width: 100%;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        border: none !important;
        margin-top: 10px;
        float: none;
        opacity: 1;
        visibility: visible;
        height: auto;
    }
    .sub-menu li {
        display: block;
    }
    .sub-menu li a {
        text-align: center;
        margin-bottom: 0 !important;
        border: none !important;
        padding: 5px 0;
        font-size: 0.85rem !important;
    }

    /* #menú */
    .btn-menu {
        transform: translateY(10px);
    }

    .menu,
    .mobile .menu {
        position: fixed;
        top: 0;
        bottom: auto;
        left: 0;
        width: 100%;
        max-width: 100%;
        background-color: var(--color-fff);
        align-items: center;
        justify-content: center;
        padding: 0 20px 0 0;
        transition: all 0.6s ease;
        z-index: 997;
        display: block;
        visibility: hidden;
        opacity: 0;
        transform: translateY(-300px);
    }

    body.visible-menu .menu,
    body.mobile.visible-menu .menu {
        visibility: visible;
        opacity: 1;
        box-shadow: 0 8px 10px 0 rgb(0 0 0 / 15%);
        transform: translateY(var(--h-header-scroll));
        border-bottom: 1px solid #ddd;
    }

    .menu a,
    .mobile .menu a {
        width: 100%;
        float: none;
        display: inline-block;
        font-size: 0.85rem;
        text-align: center;
        margin: auto;
        padding-bottom: 10px;
        opacity: 0;
        text-decoration: none;
    }

    body.visible-menu .menu a,
    body.mobile.visible-menu .menu a {
        opacity: 1;
    }

    /* btn */
    .mobile .btn-menu,
    .btn-menu {
        display: block;
    }

    /* # MAIN */
    #main {
        padding-top: var(--h-header-scroll);
    }

    /* #buscador */
    .visible-buscador #buscador {
        top: var(--h-header-scroll);
    }
    #buscador form {
        width: 270px;
        height: 35px;
        top: 100px;
    }
    #buscador .cmyNjn {
        width: 270px;
    }

    /* #filtros */
    .btn-filtro,
    .btn--carpetas {
        width: 55px;
        height: 55px;
        top: calc(var(--top-main-buscador-movil) + 40px);
    }
    .btn-filtro {
        right: 21px;
    }
    .btn--carpetas {
        right: auto;
        left: 21px;
    }
    .btn-filtro svg,
    .btn--carpetas svg {
        width: 45px;
        height: 45px;
    }

    /* grilla */
    .grilla .cont-receta {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .cyg-col-left,
    .cyg-col-right {
        width: 100%;
    }

    /* mod variantes */
    .mod-variantes section .mod-estrellas {
        transform: scale(0.8);
    }

    /* # FOOTER */
    #footer {
        position: relative;
    }

    /* redes */
    #footer .mod-redes {
        text-align: center;
        width: 100% !important;
    }

    /* address */
    #footer address {
        width: 100%;
        max-width: inherit;
        text-align: center;
        margin-bottom: 30px;
    }

    #footer address br {
        display: block;
    }

    #footer address a {
        float: none;
        margin: 0 0 15px;
    }

    /* nav */
    #footer nav {
        max-width: inherit;
        display: block;
        text-align: center;
    }

    #footer nav div {
        text-align: center;
    }

    #footer nav a {
        display: block;
        margin-bottom: 15px !important;
    }

    .receta h2 {
        font-weight: 400;
    }

    /* EDITAR */
    .container-float {
        top: var(--h-header-scroll) !important;
    }

    /* .edit-page #intro {
        margin-top: 0;
    } */

    .ichef--buscador {
        height: 100vh !important;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        margin: 0;
        padding: 10px 10px 0;
        right: unset;
        transform: unset;
        flex-direction: column;
        z-index: 999;
        background-color: #fffa;
    }
}

/* @media screen and (max-width: 645px) and (orientation: landscape) {
}
 */

/* ---------------------------------------------------------------------- */

/* 640
 
------------------------------------------------------------------------- */
@media screen and (max-width: 640px) {
    .show-mobile {
        display: block;
    }
    .show-pc {
        display: none !important;
    }
    .ichef--cont-cate {
        justify-content: center;
        grid-gap: 15px;
    }

    .ichef--cate {
        min-width: 70px;
        max-width: 70px;
    }

    .ichef--cate span {
        font-size: 0.75rem;
    }
}

/* ---------------------------------------------------------------------- */

/* 540
 
------------------------------------------------------------------------- */
@media screen and (max-width: 540px) {
    :root {
        /* medidas */
        --w-filtro: 300px !important;
    }

    .container {
        padding: 0 14px;
    }

    /* #buscador */
    #buscador form {
        width: 180px;
        height: 35px;
    }
    #buscador .cmyNjn {
        width: 180px;
    }

    /* grilla */
    .grilla .cont-receta {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .btn-filtro {
        right: 14px;
    }

    .btn--carpetas {
        right: auto;
        left: 14px;
    }

    #filtros {
        width: var(--w-filtro);
    }

    /* botonera */
    .botonera {
        width: 100% !important;
        border: none;
    }

    .botonera a {
        padding: 0 10px;
        font-size: 0.85rem;
    }

    .botonera a:nth-of-type(1) {
        padding-left: 0;
    }
    .botonera a:nth-of-type(3) {
        padding-right: 0;
        border-right: 0;
    }

    /* etiquetas */
    .etiqueta {
        font-size: 0.85rem;
        border-radius: 9px;
    }

    /* acordeon */
    .item-aco {
        font-size: 0.9rem;
    }

    #intro .mod-botones {
        margin-top: 10px;
    }

    .mod {
        margin-bottom: 30px;
        padding-bottom: 30px;
    }

    .mod-preparacion section {
        margin-bottom: 15px;
    }

    /* iconos */
    .mod-iconos {
        display: flex;
    }
    .mod-iconos div {
        margin-right: auto;
    }

    /* herramientas */
    .nav-herr {
        width: 120px;
    }
    .sec-herr {
        width: calc(100% - 120px);
        padding-left: 15px;
    }

    #buscador form input::placeholder {
        opacity: 0 !important;
    }

    /* EDITAR */
    .edit-page .container-float {
        height: auto !important;
    }
    .edit-page .float-at-right {
        display: block !important;
    }
    .edit-page .float-at-right .separ {
        display: none !important;
    }

    .edit-page .float-at-right .btn-group {
        width: 100% !important;
    }
    .edit-page .float-at-right .btn-group:nth-of-type(2) {
        margin-top: 5px;
    }

    .edit-page .float-at-right .show-group {
        position: absolute;
        top: 0 !important;
        right: 7px !important;
        bottom: 0;
        margin: auto;
        margin-left: 0 !important;
    }

    /* VARIANTES */
    .mod-variantes section {
        display: block;
        grid-column-gap: 0;
    }

    .mod-variantes section div,
    .mod-variantes section article {
        width: 100%;
        float: left;
    }
    .mod-variantes section div {
        margin-bottom: 0 !important;
    }

    .mod-variantes section article {
        margin-top: 16px;
    }
}

/* ---------------------------------------------------------------------- */

/* 430
 
------------------------------------------------------------------------- */
@media screen and (max-width: 430px) {
    #btn-buscar {
        right: 60px;
    }
    #buscador form {
        width: 150px;
        left: auto;
        right: 30px;
    }
    #buscador .cmyNjn {
        width: 150px;
    }

    /* herramientas */
    .nav-herr {
        width: 100%;
        padding-left: 15px;
        margin-bottom: 30px;
        padding-right: 15px;
    }
    .sec-herr {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    /* form pin */
    .col-form.col-form-pin form {
        display: block;
    }
    .col-form.col-form-pin form input {
        width: 100%;
        margin-bottom: 5px;
    }

    /* button */
    .col-form form button {
        width: 120px;
    }
    .col-form.col-form-pin form button {
        height: auto;
        float: left;
        margin-top: 5px;
    }

    /* edit - crear */
    .row-nutri {
        display: block !important;
        padding-bottom: 5px;
        margin-bottom: 5px;
        border-bottom: 1px solid var(--color-ddd);
    }

    .row-nutri .col-1 {
        width: 100% !important;
        min-width: inherit !important;
        max-width: inherit !important;
        padding-bottom: 0;
    }

    .ichef--cont-cate {
        justify-content: flex-start;
        grid-gap: 5px;
        padding-bottom: 0;
    }

    .ichef--cate {
        margin-top: 5px;
        min-width: 60px;
        max-width: 60px;
    }

    .ichef--cate i {
        height: 30px;
    }
    .ichef--cate i::before {
        width: 30px;
        height: 30px;
    }

    .ichef--cate span {
        font-size: 0.65rem;
    }
}

/* ---------------------------------------------------------------------- */

/* 375
 
------------------------------------------------------------------------- */
@media screen and (max-width: 375px) {
    .step-info .row-step .col {
        width: 100%;
    }

    .recipe-cont .mod-estrellas {
        flex-direction: column;
        align-items: flex-start;
    }

    .recipe-cont .mod-estrellas .btn-calificar.btn {
        margin-top: 18px;
        margin-left: 0;
    }
}

/* ---------------------------------------------------------------------- */

/* 360
 
------------------------------------------------------------------------- */
@media screen and (max-width: 360px) {
    :root {
        /* medidas */
        --w-filtro: 260px !important;
    }

    #filtros {
        width: var(--w-filtro);
    }

    .visible-filtro .btn-filtro {
        width: 42px;
        height: 42px;
        right: calc(var(--w-filtro) + 8px);
    }
    .visible-filtro .btn-filtro::after {
        width: 16px;
        height: 16px;
    }
}

.btn {
    margin-top: 4px;
    padding: 6px 10px;
    color: white;
    border-radius: 6px;
    cursor: pointer;
    border: 1px solid var(--color-verde);

    &:hover {
        background-color: white !important;
        color: #509f2c;
    }
}

.btn.btn-primary {
    background-color: var(--color-verde);
    font-size: 1rem !important;
    margin-left: 10px;

    &.is-disabled {
        opacity: 0.5 !important;
        filter: grayscale(100%);
        margin-left: 0;
        cursor: default !important;

        &:hover {
            opacity: 0.5 !important;
            background-color: var(--color-verde) !important;
            border-color: var(--color-verde) !important;
            color: var(--color-fff) !important;
        }
    }
}

.separator {
    margin: 20px 10px;
}

th {
    font-weight: bold;
}

td {
    text-align: center;
}

h4 {
    font-weight: bold;
    margin-bottom: 10px;
}

tr:nth-child(even) {
    background-color: #f2f2f2;
}

.align-right {
    display: flex;
    justify-content: flex-end;
}

.listInput {
    border: 1px solid #ccc;
    text-align: center;
    border-radius: 6px;
    margin: 0 5px;
    width: 40%;
}

.side-by-side {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 8px 0;
}

.checkbox-label {
    margin-left: 10px;
}

input[type='checkbox'].visible {
    display: inline;
    appearance: auto;
    width: 10%;
}

/* categorias */
.ichef--cont-cate {
	width: 100%;
	display: inline-flex;
	grid-gap: 20px;
	align-items: flex-start;
	justify-content: space-between;
	padding: 15px 0px;

	.ichef--cate {
		width: auto;
		max-width: 100px;
		display: flex;
		flex-direction: column;

		/* ico */
		i {
			width: 100%;
			height: 35px;
			text-align: center;
			overflow: hidden;
			border-radius: 50%;
		}

		i::before {
			content: '';
			width: 35px;
			height: 35px;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			display: inline-grid;
		}

		/* tx */
		span {
			width: 100%;
			text-align: center;
			font-size: 0.85rem;
			font-weight: 500;
			color: var(--color-gris);
			margin-top: 2px;
		}

		//Deben existir los iconos en blanco y verde en la ruta src\components\filtersHeader\icons con el formato {$filterId}_verde.svg y {$filterId}_blanco.svg

		/*
		{
			"0": "Sin icono",
			"173": "Bebidas",
			"174": "Salsas y Dips",
			"175": "Carnes",
			"176": "Pescados y Mariscos",
			"177": "Pasta y Arroz",
			"178": "Panadería",
			"179": "Ensaladas y Guarniciones",
			"180": "Sopas",
            "181": "Repostería",
			"182": "Postres",
			"183": "Básicos",
			"184": "Varios",
			"185": "Tartas, Empanadas y Pizzas",
			"186": "Blw (alimentación complementaria para bebés)",
		}
		*/
		$filterIds: 0, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185, 186;

		@each $filterId in $filterIds {
			&.cate-#{$filterId} i::before {
				background-image: url('./icons/#{$filterId}_blanco.svg');
			}

			&.cate-#{$filterId}.activo i::before {
				background-image: url('./icons/#{$filterId}_verde.svg');
			}
		}

		&.btn-filter-clear i::before {
			background-image: url('../../images/svg/ico-escoba.svg');
		}

		&.btn-filter-clear.activo i::before {
			background-image: url('../../images/svg/ico-escoba.svg');
		}

		@media screen and (min-width: 769px) {
			@each $filterId in $filterIds {
				&.cate-#{$filterId}:hover i::before {
					background-image: url('./icons/#{$filterId}_verde.svg');
				}
			}

			&.btn-filter-clear:hover i::before {
				background-image: url('../../images/svg/ico-escoba.svg');
			}
		}
	}

	.swiper-wrapper {
		/*justify-content: center;*/
		width: 100%;
		order: 2;
	}

	.swiper-button-next,
	.swiper-button-prev {
		position: relative;
		width: 0px;
		color: var(--color-verde);
	}

	.swiper-button-next::after,
	.swiper-button-prev::after {
		font-size: 25px;
	}

	.swiper-button-prev {
		order: 1;
	}

	.swiper-button-next {
		order: 3;
	}

	@media screen and (max-width: 900px) {
		.swiper-wrapper {
			width: 84%;

			.swiper-slide {
				width: fit-content !important;
			}
		}
	}
}

/* stylelint-disable selector-class-pattern */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable block-no-empty */

/* root */
:root {
    /* colores */
    --color-verde: #509f2c;
    --color-verde-rgba-1: rgb(178 207 103 / 47%);
    --color-verde-rgba-2: rgb(219 232 183 / 29%);
    --color-gris: #6c6b6c;
    --color-000: #000;
    --color-fff: #fff;
    --color-f5f: #f5f5f5;
    --color-ddd: #ddd;
    --color-eee: #eee;
    --color-ccc: #ccc;
    --color-999: #999;
    --color-666: #666;
    --color-333: #333;
    --color-placeholder: var(--color-999);

    /* font */
    --font: 'Fira Sans', sans-serif;

    /* medidas */
    --w-filtro: 400px;
    --h-footer: 155px;

    /* height */
    --h-header: 100px;
    --h-header-scroll: 50px;
    --h-buscador: 150px;
    --h-buscador-scroll: 70px;
    --top-main: var(--h-header);
    --top-main-buscador: calc(var(--h-header) + var(--h-buscador));

    /* radius */
    --radius-8: 8px;
    --radius-12: 12px;
}

.edit-page {
    box-sizing: border-box !important;

    /*
	#allSteps {
		section {
			padding-left: 5px;
			border-left: 4px #509f2c99 solid;
			margin-bottom: 10px;
			border-radius: 6px;
		}		
	}
	*/

    .mod-etiquetas {
        .css-1s2u09g-control,
        .css-1s2u09g-control:hover {
            box-shadow: none !important;
            border: 1px solid var(--color-verde) !important;
            border-color: var(--color-verde) !important;
        }
        .css-b62m3t-container {
            .css-1s2u09g-control {
                border-radius: 5px;
                border: 1px solid #509f2c;
                padding: 0;
            }
        }
    }

    .vg {
        display: flex;
        flex-wrap: wrap;
    }

    .click-imp {
        cursor: pointer;
    }

    .link-recipe-page {
        text-decoration: none;
        color: inherit;
    }

    input {
        border-radius: 5px;
        border: 1px solid var(--color-verde);
        padding: 10px;
    }

    select,
    .mini-fld .rc-input-number-input {
        border: 1px solid var(--color-verde);
        padding: 1px 0 1px 3px;
        font-size: 12px;
        color: var(--color-verde);
    }

    .mini-fld .rc-input-number-input:read-only {
        border: none;
        background-color: transparent;
    }

    select {
        padding: 1px 10px 1px 3px;
    }

    textarea {
        border-radius: 5px;
    }

    .remove-content {
        position: relative;
    }

    .remove {
        width: 14px;
        position: absolute;
        right: 10px;
        margin-top: 12px;
        cursor: pointer;
    }

    .remove-tag {
        width: 14px;
        position: relative;
        cursor: pointer;
        margin: auto;
    }

    .remove-step {
        float: right;
        margin: 2px 2px 0 0;
    }

    &.editing {
        h5 {
            //border: 1px var(--color-verde) solid;
            border-radius: 4px;
            padding: 8px;
            margin-bottom: 4px !important;
        }

        textarea {
            border: 1px var(--color-verde) solid;
            padding: 5px !important;
            font-size: 0.9rem;
            color: #555;
            min-height: 60px;
        }
    }

    .cooking-info {
        display: flex;
        flex-direction: column;
        white-space: nowrap;
        margin-bottom: 10px;

        .mini-fld .rc-input-number-input {
            border-radius: 4px;
            padding: 3px 5px;
            font-size: 12px;
            color: var(--color-verde);
        }

        > div {
            flex: 1;
            padding: 4px 0;

            > div {
                margin-right: 6px;
            }
        }
    }

    .text-area {
        display: flex;
        flex-direction: column;
        white-space: nowrap;

        > div {
            flex: 1;
            padding: 4px 0;
            flex-direction: row;
        }
    }

    .porciones-fld {
        text-indent: 6px;
    }

    .cooking-info input {
        min-height: 24px;
    }

    .nutri-values {
        display: inline-block;
        width: 100%;

        .mini-fld .rc-input-number-input {
            min-width: 80px;
            padding: 3px;
            border-radius: 5px;
        }

        .col-3 {
            font-size: 12px;
        }

        > div {
            display: flex;
            flex: 1;

            > div {
                flex: 1;
                padding: 6px 5px 6px 0;

                &:first-child {
                    flex: 3;
                    font-weight: bolder;
                }

                &:last-child {
                    flex: 3;
                }
            }
        }
    }

    .mod-ingredientes {
        .mini-fld .rc-input-number-input {
            padding: 3px 7px;
            font-size: 14px;
            color: #000;
            font-weight: bold;
        }

        p {
            margin-bottom: 8px;
        }
    }

    .btn {
        margin-top: 4px;
        padding: 6px 10px;
        color: white;
        border-radius: 6px;
        cursor: pointer;
        border: 1px solid var(--color-verde);

        &:hover {
            background-color: white !important;
            color: var(--color-verde);
        }
    }

    .tags {
        width: auto;
        padding: 5px 10px;
        background-color: var(--color-fff);
        border: 1px solid var(--color-verde);
        color: #999;
        font-weight: 500;
        border-radius: var(--radius-8);
        margin: 0 10px 10px 0;
        display: inline-flex;
        cursor: pointer;
        font-size: 0.9rem;
        font-family: 'Fira Sans', sans-serif;
    }

    .add-tag {
        display: inline-block;
        width: 200px;
        margin: 0 5px 5px 0;

        .css-1s2u09g-control {
            border: 1px solid var(--color-verde);
            color: var(--color-verde);
            font-weight: 500;
        }

        color: var(--color-gris) !important;

        * {
            color: var(--color-gris) !important;
        }
    }

    .full-select {
        display: block;
        width: 100%;
    }

    .add-ingredient {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;

        > div {
            flex: 1;
            white-space: nowrap;

            &:first-child {
                flex: 5;
            }
        }

        button {
            margin: 0 0 0 6px !important;
            padding: 2px 6px !important;
            border-radius: 3px !important;
        }
    }

    .step-info {
        display: flex;
        flex-direction: column;
        font-size: 0.8rem;

        ::placeholder {
            font-size: 0.8rem !important;
        }

        input {
            max-height: 30px;
            font-size: 0.8rem;
            padding: 4px;
        }

        /*
		> div {
			display: flex;
			flex: 1;
			margin: 2px;
			align-items: center;
			font-size: 12px;

			> div {
				display: flex;
				flex: 1;
				margin: 2px;
				align-items: center;

				> div:last-child {
					flex: 4;
					margin-left: 3px;
				}
			}
		}
		*/
    }

    .i-box {
        display: flex;
        flex: 1;
        text-transform: uppercase;
        color: var(--color-verde);

        select {
            text-transform: uppercase;
        }

        > div {
            font-family: var(--font);
            flex: 1;
            margin: 0 5px;
            text-align: center;

            > div {
                margin-top: 6px;
                width: 100%;
            }
        }

        span {
            width: inherit;
            float: none;
        }

        .d-flex div {
            flex: 1;
            font-size: 12px;
        }
    }

    .d-flex > div {
        &:first-child {
            flex: 3;
        }

        &:last-child {
            margin: 3px 2px;
        }
    }

    .i-box > div:first-child > div {
        margin-top: 6px !important;
    }

    .i-box-img {
        width: 60px !important;
        height: 60px !important;
    }

    .time-ctrl {
        input {
            /*margin: 3px 0 0 4px;*/
            padding: 0;
        }

        .rc-time-picker-clear {
            display: none !important;
        }
    }

    .cont-select-nombre-receta {
        b {
            font-weight: bolder;
        }

        table {
            border-spacing: 5px 10px;

            td {
                padding: 0 5px;
                font-size: 0.9rem;

                &:first-child {
                    text-align: right;
                }

                &:last-child {
                    display: table-cell;
                    border-left: 1px solid #ccc;
                }
            }
        }
    }

    .nombre-unidad {
        display: inline-block;
        position: absolute;
        right: 16px;
        margin-top: 10px;
    }

    .rc-time-picker-panel-combobox {
        display: inline-flex;
    }

    .container-float {
        position: fixed;
        top: var(--h-header);
        right: 0;
        left: 0;
        z-index: 2000;
        padding: 0 14px;
        max-width: inherit !important;
        background-color: #f5f8ea;
        border-bottom: 1px solid #ccc;
        height: 46px;
    }

    .float-at-right {
        width: 100%;
        float: right;
        right: auto;
        padding: 6px 6px 4px 10px;
        z-index: 2000;
        display: flex;
        align-items: center;
        justify-content: center;

        .separ {
            padding-left: 6px;
            margin-right: 10px;
            border-right: 1px solid var(--color-gris);
            display: inline-block;
            position: relative;
            height: 30px;
            overflow: auto;
        }

        .show-group {
            border: 1px solid var(--color-verde);
            width: 32px;
            height: 30px;
            margin-left: 10px;
            padding: 4px;
            background-color: #fff;
            .btn-eye {
                height: 20px;
            }

            top: -2px;
        }
        .btn-group {
            border-radius: 10px !important;
            display: inline-block;
            overflow: auto;

            &.separ-right {
                margin-right: 5px;
                border-right: 1px solid black;
            }

            &.hidden {
                display: none;
            }

            .btn {
                color: var(--color-gris);
                background-color: #fff;
                border: 1px solid var(--color-verde) !important;
            }

            .btn:hover {
                color: black !important;
            }

            .btn-selected {
                background-color: #509f2c;
                color: white !important;

                &:hover {
                    background-color: #418024 !important;
                    color: white !important;
                }
            }

            button {
                border: none !important;
                color: var(--color-gris) !important;
                margin: 0 3px !important;
                font-weight: bold;
            }
        }
    }

    input,
    textarea,
    select {
        height: auto !important;
        font-size: 0.95rem !important;
        color: var(--color-gris) !important;
    }

    .container-dnd {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100% !important;
        background-color: var(--color-f5f) !important;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        border: none !important;
        border-radius: 0 !important;

        span {
            color: var(--color-verde) !important;
            padding: 20px;
            border: 3px dashed var(--color-verde);
            padding-top: 130px;
            background-color: transparent;

            &::before {
                content: '';
                width: 88px;
                height: 88px;
                position: absolute;
                top: 20px;
                right: 0;
                left: 0;
                margin: auto;
                background-image: url('../../images/ico-drop.png');
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
            }
        }

        &:hover span {
            background-color: #eeeeee7f
        }
    }

    .container-buttons {
        border: none !important;
        background-color: transparent !important;

        button {
            background-color: var(--color-verde) !important;
            margin-top: 4px;
            padding: 6px 10px;
            color: var(--color-fff) !important;
            border-radius: 6px;
            border: 1px solid var(--color-verde) !important;
            cursor: pointer;
            font-weight: normal !important;
            box-shadow: none !important;
            text-shadow: none !important;

            &:hover {
                background-color: var(--color-fff) !important;
                color: var(--color-verde) !important;
            }
        }
    }

    .img-uploaded input + div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .image-prev {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .sel-plantilla {
        background-color: var(--color-verde);
        margin-top: 4px;
        padding: 6px 10px;
        color: var(--color-fff) !important;
        border-radius: 6px;
        border: 1px solid var(--color-verde);
        cursor: pointer;
        margin-bottom: 10px;
        display: inline-block;

        &:hover {
            background-color: var(--color-fff);
            color: var(--color-verde) !important;
        }
    }

    .select-container {
        margin-bottom: 10px;
        span:nth-of-type(2) + div {
            border: 1px solid var(--color-verde) !important;
        }
    }

    .cyg-col-right {
        .cont p input {
            padding: 15px !important;
            color: var(--color-gris) !important;
            font-size: 1.15rem !important;
        }

        .descripcion {
            margin-top: 10px;
            margin-bottom: 10px;
            padding: 5px 15px;
            color: var(--color-gris);
            font-size: 1.0rem;
        }

        .i-box-img {
            width: 48px !important;
        }

        .i-box .d-flex {
            align-items: center;
            margin-top: 0 !important;
        }

        select,
        .mini-fld .rc-input-number-input {
            color: var(--color-gris) !important;
        }

        select {
            padding-right: 8px !important;
            min-width: 95px;
        }
    }

    .mod-i {
        bottom: 23px;
        position: absolute;
        padding: 0 40px 0 20px;
        width: 100%;

        input,
        select {
            border: 0 !important;
            font-size: 0.8rem !important;
        }

        .d-flex {
            height: 28px !important;
            max-height: 28px;
            min-height: 28px;
            border-radius: 4px;
            padding-right: 2px;
            white-space: nowrap;
        }
    }

    &.preview {
        .row-nutri {
            display: flex;

            .col-1 {
                width: 190px !important;
                max-width: 190px !important;
                min-width: 190px !important;
            }

            .col-2 {
                display: inline-block;
                font-size: 1rem;
            }
        }

        .content-ingredient {
            font-weight: 500;
        }
        .mod-i {
            .d-flex {
                margin-top: 0 !important;
                justify-content: center;
                > div {
                    flex: 0;
                }
            }
            i {
                margin-top: -10px;
            }
        }
    }

    &.editing {
        .mod-i {
            .d-flex {
                border: 1px solid var(--color-verde);
            }
        }
    }

    .editing.cooking-step h5 {
        padding: 10px 10px 10px 14px;
    }

    .row-step .col svg {
        top: -3px;
    }

    .step-info {
        .row-step {
            display: block;
            width: 100%;
            float: left;

            .col {
                display: block;
                float: left;
                margin: 0 5px 0 0 !important;
                box-sizing: border-box;

                .label {
                    width: 100%;
                    float: left;
                    margin: 0;

                    + div {
                        width: 100%;
                        float: left;
                        margin: 0;
                    }
                }

                input {
                    height: 36px;
                    max-height: 36px;
                }
            }
        }
    }

    .mini-fld .rc-input-number-input,
    .info {
        textarea {
            padding: 10px;
        }
    }

    .editing.cooking-step .text-area div {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    svg {
        fill: var(--color-verde) !important;
    }

    .row-nutri {
        display: flex;
        align-items: center;

        .col-1 {
            width: 190px !important;
            max-width: 190px !important;
            min-width: 190px !important;
        }

        .col-2 {
            display: flex;
            align-items: center;
            font-size: 0.8rem;
        }

        input {
            width: 75px;
            max-width: 75px !important;
            min-width: inherit !important;
            height: 25px !important;
            margin-right: 5px;
        }
    }

    .step-content {
        border-top: 0 !important;
        border-bottom-right-radius: 5px !important;
        border-bottom-left-radius: 5px !important;
        display: inline-block;
        padding: 0 !important;
        position: absolute;
        top: 0;

        .nombre-unidad {
            top: 22px !important;
        }

        .css-b62m3t-container {
            display: inline-block;
        }

        .css-319lph-ValueContainer,
        .css-tlfecz-indicatorContainer {
            padding: 2px !important;
        }
    }

    .btn.btn-primary {
        background-color: var(--color-verde);
        font-size: 1rem !important;

        &.is-disabled {
            opacity: 0.5 !important;
            filter: grayscale(100%);
            margin-left: 0;
            cursor: default !important;

            &:hover {
                opacity: 0.5 !important;
                background-color: var(--color-verde) !important;
                border-color: var(--color-verde) !important;
                color: var(--color-fff) !important;
            }
        }
    }

    .content-ingredient {
        //border: 1px solid var(--color-verde-rgba-1);
        padding: 5px;
        margin-bottom: 5px !important;
        border-radius: 0 !important;

        .edit-cont-select {
            width: 62.5% !important;
            float: left;
        }
        .input-gramos {
            width: 35% !important;
            float: left;
            margin-left: 2.5% !important;
            height: 38px !important;

            input {
                height: 38px !important;
            }

            .nombre-unidad {
                font-size: 0.85rem !important;
            }
        }

        .add-ingredient .remove-content + div {
            width: 100%;
        }
    }

    .add-ingredient > div {
        margin: 0 0 5px !important;
        display: block !important;
        width: 100% !important;
        flex: inherit !important;

        > div {
            margin: 0 0 5px !important;
            display: block !important;
            width: 100% !important;
            flex: inherit !important;
        }
    }

    .edit-cont-select .full-select > div {
        border-color: var(--color-verde) !important;
    }

    .add-ingredient .sec-eliminar--ing button {
        float: right;
        background-color: transparent !important;
        padding: 5px !important;
        cursor: pointer;
        display: inline-flex;
        align-items: center;

        > img {
            max-height: 16px;
        }

        border: 1px solid #41802400 !important;

        &:hover {
            border: 1px solid #418024 !important;
            background-color: #41802422 !important;
        }
    }

    .remove-content img {
        display: none;
    }

    .btn {
        &.btn-previa {
            margin-left: 10px;
        }
        &.btn-salir {
            background-color: var(--color-gris);
        }
        &.btn-enviar {
            &.is-disabled {
                opacity: 0.5 !important;
                filter: grayscale(100%);
                margin-left: 0;
                cursor: default !important;

                &:hover {
                    opacity: 0.5 !important;
                    background-color: var(--color-verde) !important;
                    border-color: var(--color-verde) !important;
                    color: var(--color-fff) !important;
                }
            }

            margin-top: 0 !important;
            padding: 12px 24px;
            font-size: 1.1rem;
            float: left;

            span {
                display: flex;
                align-items: center;

                &::after {
                    content: '';
                    width: 15px;
                    height: 15px;
                    background-image: url('../../images/ico-guardar.svg');
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    float: right;
                    margin-left: 10px;
                    display: none;
                }
            }

            &:hover span::after {
                background-image: url('../../images/ico-guardar-hover.svg');
            }

            &.pc {
                display: block;
            }

            &.movil {
                display: none;
            }
        }
    }

    .sweet-loading {
        position: fixed;
        top: 0;
        height: 100vh;
        width: 100vw;
        background-color: #509f2c33;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 4000;
    }
    .mod-validar {
        display: flex;
        justify-content: flex-start;
    }
    .mod-imprimir {
        display: flex;
        justify-content: flex-end;
    }

    .cont {
        table {
            width: 100%;
            margin: 0;
        }
    }

    .cont-check {
        position: absolute;
        padding-top: 3px;

        span {
            font-size: 14px;
        }

        input[type='checkbox'] {
            display: inline-block !important;
            width: auto !important;
            border: inherit;
            appearance: auto !important;
            margin: 3px 8px 3px 3px;
            transform: scale(1.5);
        }
    }

    .sec-btn-ingredientes {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    /* #preparacion */
    .mod-preparacion {
        /* cont */
        .cont {
            padding: 10px;
            margin-bottom: 10px;
            border: 1px solid var(--color-verde);

            /* pasos */
            .sec-paso {
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                /* num paso */
                .paso {
                    font-weight: bold;
                    color: var(--color-verde);
                    font-size: 1.25rem;
                    transform: translateY(2px);
                    white-space: nowrap;
                    margin-right: 10px;
                }

                /* operaciones */
                .operaciones {
                    #nivel-velocidad,
                    #cantidad,
                    #temperatura,
                    #potencia {
                        width: 40px !important;
                    }

                    input {
                        //margin-left: 10px;
                        padding: 0 !important;
                        border: none;
                        font-size: 14px !important;
                    }

                    .fld {
                        /* border: 1px solid #509f2c;
						border-radius: 5px; */
                        margin-right: 4px;

                        /* min-height: 38px; */
                        float: left;

                        &-addon {
                            position: absolute;
                            right: 6px;
                            top: 30px;
                            z-index: 10;
                            font-size: 10px;
                        }

                        &-label {
                            /* position: absolute; */
                            font-size: 10px;
                            padding: 2px;
                        }

                        &-content {
                            height: 38px;
                            background-color: #fff;
                            border: 1px solid #ccc;
                            border-radius: 4px;
                            overflow: hiiden;
                            padding: 3px !important;
                            display: flex;
                            align-items: center;
                        }

                        &-content-tp {
                            height: 38px;

                            * {
                                font-size: 14px !important;
                                height: 38px;
                            }

                            input {
                                height: 38px !important;
                                padding: 5px !important;
                                box-sizing: border-box;
                                border: 1px solid #ccc;
                            }
                        }
                    }

                    /* TRABAJO CON LOS REACT-SELECT */

                    .css-b62m3t-container {
                        // overflow: hidden;

                        // .css-26l3qy-menu {
                        //     background-color: white;
                        // }

                        border: none;

                        .css-1s2u09g-control,
                        .css-1pahdxg-control {
                            background-color: transparent;
                            border: none !important;
                            box-shadow: none;
                            &:hover {
                                box-shadow: none;
                            }
                        }
                    }

                    display: flex;
                    align-items: center;

                    /* flex: 10 1 auto; */
                    //width: calc(100% - 130px);

                    /* background: red; */
                    font-size: 14px !important;

                    .time-ctrl {
                        input {
                            // margin: 0px;
                            // padding: 7px 8px 8px;
                            max-width: 70px;
                        }
                        .rc-time-picker-clear {
                            display: none !important;
                        }
                    }

                    /* campos mas cortos */
                }

                .align-right {
                    flex: 1;
                    display: flex;
                    justify-content: flex-end;
                    padding-right: 30px;
                }

                /* controles */
                .controles {
                    text-align: right;
                    white-space: nowrap;
                    flex: auto;
                    //right: 18px;
                    width: 106px;
                    button {
                        background-color: var(--color-verde-rgba-2);
                        border: 1px solid var(--color-verde-rgba-1) !important;
                        margin-left: 3px;
                        padding: 2px;
                        height: 30px;
                        width: 30px;
                        cursor: pointer;
                        border-radius: 5px !important;
                        font-size: 0.8rem;

                        img {
                            width: 14px;
                            margin: 0 3px;
                        }

                        &:hover {
                            background-color: var(--color-fff);
                            border: 1px solid var(--color-verde) !important;
                        }

                        &.eliminar {
                            border: 1px solid #da7777 !important;
                            background-color: 1px solid #da7777 !important;
                        }
                    }
                }
            }

            /* textarea */
            .sec-text-area {
                width: 100%;
                float: left;

                textarea {
                    border-radius: 5px;
                }
            }

            /* eliminar */
            .sec-eliminar {
                width: 100%;
                float: left;
                margin-bottom: 0 !important;

                button {
                    float: right;
                    border: 0 !important;
                    background-color: transparent !important;
                    color: #b50000 !important;
                    padding: 5px 0 5px 5px !important;
                    cursor: pointer;
                    display: inline-flex;
                    align-items: center;
                    font-weight: 500;
                    position: relative !important;
                    width: auto !important;
                    right: auto !important;

                    &::before {
                        content: '';
                        width: 18px;
                        height: 18px;
                        display: inline-block;
                        background-image: url('../../images/ico-eliminar.svg');
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center;
                        transform: translateY(-1px);
                    }

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        /* nuevo paso */
        .sec-nuevo-paso {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }
}

.api-response-msg {
    float: right;
    padding: 10px;
    border: 1px solid #ccc;
    border-top: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    max-width: 300px;
    color: white;
    opacity: 0;
    transition: opacity 1s;

    &.show {
        opacity: 1;
    }

    &.bg-success {
        background-color: #509f2c;
    }

    &.bg-danger {
        background-color: #b45252;
    }
}

.scroll-down {
    .edit-page {
        .container-float {
            top: var(--h-header-scroll);
        }
    }
}

.editing {
    .as-scroll {
        overflow: auto;
        overflow-x: hidden;
        max-height: calc(100vh - 210px);
    }
}

.inserted,
.moving {
    border: #509f2c99 4px dashed;
    display: block;
    background-color: #509f2c33;
    z-index: 5000;
    height: 0;
    opacity: 1;
    transition: all 0.7s, opacity 0.7s;
}

.footer-buttons {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 200;
    padding: 10px;
    background-color: var(--color-fff);
    border-top: 1px solid var(--color-eee);
}

.footer-buttons .cyg-row {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-buttons .cyg-row .cyg-col-right {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-buttons .cyg-row .cyg-col-right button {
    padding: 10px 22px !important;
    display: inline-flex !important;
}

/* RESPONSIVE ------------------------------------------------------ */

/* 1380 */
@media screen and (max-width: 1380px) {
    .edit-page .cyg-col-right .cont p input {
        padding: 10px !important;
        font-size: 1rem !important;
    }
    .edit-page .cont-select-nombre-receta table {
        transform: translateY(-10px);
        line-height: 1;
    }
    .edit-page .cont-select-nombre-receta table td {
        font-size: 0.85rem !important;
    }

    .edit-page .mod-i {
        bottom: 30px !important;
    }
}

/* 1025 */
@media screen and (max-width: 1025px) {
    .sec-paso {
        display: block !important;
        .controles {
            margin-top: 6px;
        }
    }
    .edit-page .cont-select-nombre-receta table {
        transform: translateY(-18px);
        line-height: 1;
    }
    .edit-page .cont-select-nombre-receta table td {
        font-size: 0.8rem !important;
    }

    .edit-page .mod-i {
        bottom: 20px !important;
    }

    /* #preparacion */
}

/* 900 */
@media screen and (max-width: 900px) {
    .footer-buttons .cyg-row .cyg-col-left {
        display: none;
    }
    .footer-buttons .cyg-row .cyg-col-right {
        width: 100% !important;
    }
}

/* 640 */
@media screen and (max-width: 640px) {
    .sec-paso {
        display: block !important;
    }
    .edit-page .cont-select-nombre-receta table {
        transform: translateY(0);
        line-height: 1;
    }
    .edit-page .cont-select-nombre-receta table td {
        font-size: 0.9rem !important;
    }

    .edit-page .mod-i {
        bottom: 30px !important;
    }
}

.left-popup {
    z-index: 2000;
    display: flex;
    position: absolute;
    background: white;
    width: auto !important;
    flex-direction: column;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 4px #5555;
    min-width: 200px;
    ins {
        flex: 1;
        width: inherit !important;
        white-space: nowrap;
        padding: 2px 10px;
    }

    &::before {
        content: '';
        position: absolute;
        display: block;
        width: 0;
        z-index: 1;
        border-style: solid;
        border-color: transparent #fff;
        border-width: 8px 0 8px 10px;
        top: 20px;
        right: -10px;
        margin-top: -8px;
    }
}





.right-popup {
    z-index: 2000;
    display: flex;
    position: absolute;
    background: white;
    width: auto !important;
    flex-direction: column;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 4px #5555;
    min-width: 200px;
    ins {
        flex: 1;
        width: inherit !important;
        white-space: nowrap;
        padding: 2px 10px;
    }

    &::before {
        content: '';
        position: absolute;
        display: block;
        width: 0;
        z-index: 1;
        border-style: solid;
        border-color: transparent #fff;
        border-width: 8px 10px 8px 0px;
        top: 20px;
        left: -10px;
        margin-top: -8px;
    }
}

.wrapper-recipe-list {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
}

.loaded {
    display: block;
}

.to-load {
    display: none;
}

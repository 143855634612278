.recipe-cont {
    .vg {
        display: flex;
        flex-wrap: wrap;
    }

    .click-imp {
        cursor: pointer;
    }

    .link-recipe-page {
        text-decoration: none;
        color: inherit;
    }

    .icon-svg {
        font-size: 2rem;
        color: var(--color-gris);
    }

    .btn {
        padding: 6px 10px;
        color: white;
        border-radius: 6px;
        cursor: pointer;
        border: 1px solid var(--color-verde);

        &:hover {
            background-color: white !important;
            color: #509f2c;
        }
    }
    .btn-clon{
        margin: 10px 0px;
    }
    .btn.btn-primary {
        background-color: var(--color-verde);
        font-size: 1rem !important;
    }

    .btn.btn-salir {
        background-color: var(--color-gris);
    }

    .btn-editar {
        display: flex;
        align-items: center;
    }

    .cont-select-nombre-receta {
        b {
            font-weight: bolder;
        }

        table {
            border-spacing: 5px 10px;

            td {
                padding: 0 5px;
                font-size: 0.9rem;

                &:first-child {
                    text-align: right;
                }

                &:last-child {
                    display: table-cell;
                    border-left: 1px solid #ccc;
                }
            }
        }
    }

    .section-flex {
        display: flex;
    }

    .space-right {
        margin-right: 10px;
    }

    .half-size {
        scale: 50%;
        width: 120px;
    }

    .white-text {
        color: white;
    }

    .same-line {
        width: 150px;
    }

    .comment-container {
        overflow-y: auto;
        max-height: 450px;
    }

    .comment {
        margin-top: 10px;
        padding: 10px;
        border-radius: 6px;
        border: 1px solid #ccc;
        background-color: #f5f5f5;
        font-size: 0.9rem;
    }

    .comment-user {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
    }

    .comment-username {
        font-weight: bold;
        width: 100px;
    }

    .comment-date {
        font-size: 0.8rem;
        color: #666;
        padding-left: 10px;
        padding-right: 20px;
    }

    .comment-text {
        font-size: 0.9rem;
        max-width: 400px;
    }

    .comment-delete {
        padding-top: 12px;
    }

    .comment-new {
        margin-top: 10px;
        padding: 10px;
        border-radius: 6px;
        border: 1px solid #ccc;
        background-color: #f5f5f5;
        font-size: 0.9rem;
        display: grid;
    }

    .yellow {
        background-color: #FFFF007F;
        margin-bottom: 10px;
    }

    .comment-note {
        font-weight: bold;
    }

    .comment-input {
        width: 100%;
        height: 100px;
        border: 1px solid #ccc;
        border-radius: 6px;
        padding: 10px;
        font-size: 0.9rem;
        resize: none;
        margin-bottom: 10px;
    }

    .comment-btn {
        padding: 6px 10px;
        color: var(--color-verde);
        border-radius: 6px;
        cursor: pointer;
        border: 1px solid var(--color-verde);
        align-self: flex-end;
        background-color: white;
 
        &:hover {
            background-color: var(--color-verde);
            color: white;
        }
    }

    .btn-right {
        float: right;
        margin: 2px 2px 0 0;
    }

    .comment-remove-btn {
        margin-left: 5px;
        padding: 6px 10px;
        color: var(--color-rojo);
        border-radius: 6px;
        cursor: pointer;
        border: 1px solid var(--color-rojo);
        align-self: flex-end;
        background-color: white;
 
        &:hover {
            background-color: var(--color-rojo);
            color: white;
        }
    }
}

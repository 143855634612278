/* table.scss */

/* Define table styles */
$table-border-color: #ddd;
$table-header-bg: #f2f2f2;
$table-row-hover-bg: #f9f9f9;

.sort-div {
  margin-bottom: 10px;
}

.submit-btn {
  padding: 6px 10px;
  color: var(--color-verde);
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid var(--color-verde);
  align-self: flex-end;
  background-color: white;
  min-width: 110px;

  &:hover {
      background-color: var(--color-verde);
      color: white;
  }
}

.narrow {
  min-width: 95px !important;
}

.wide {
  min-width: 320px !important;
}

.referrals {
  max-width: 750px;

/* Table styles */
table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid $table-border-color;

  /* Table header styles */
  thead {
    background-color: $table-header-bg;

    th {
      padding: 12px;
      border-bottom: 1px solid $table-border-color;
    }
  }

  /* Table body styles */
  tbody {
    tr {
      &:hover {
        background-color: $table-row-hover-bg;
      }

      td {
        padding: 6px;
        border-bottom: 1px solid $table-border-color;
        min-width: 160px;
      }
    }
  }
}
}
@media print {
    /* configuraciones impresora */
    @page {
        size: a4 vertical;
        margin: 0.5cm 0;
    }

    /* 1era hoja */
    @page :first {
        margin-top: 1cm;
    }

    /* ocultar */
    #header,
    .bg-textura,
    .mod-botones,
    #footer,
    #btn-imprimir {
        display: none !important;
        visibility: hidden !important;
        height: 0 !important;
    }

    /* main */
    #root,
    #main {
        padding: 0 !important;
    }

    /* tit - tx */
    .tit {
        margin-bottom: 8px;
    }
    h3 {
        font-size: 13pt;
    }
    h5 {
        font-size: 11pt;
        margin-bottom: 0 !important;
        line-height: 1 !important;
    }
    p,
    span,
    a {
        font-size: 10pt;
        line-height: 1.2;
    }

    /* intro */
    #intro {
        margin-bottom: 20px;
    }

    /* cont */
    #intro .container {
        position: relative;
        margin-top: 0;
    }

    /* bg */
    #intro .bg-textura {
        height: 380px;
    }

    /* col */
    #intro .cyg-col-right .cont {
        padding: 15px;
    }

    /* info receta */
    #intro .cyg-col-left::after,
    #intro .cyg-col-right::after {
        height: 5px;
    }

    /* tx */
    #intro h2 {
        font-size: 18pt;
        line-height: 1;
    }

    .mod-estrellas {
        margin-top: 10px;
    }
    .mod-estrellas svg {
        width: 18px;
        height: 18px;
    }

    /* iconos */
    #intro .mod-iconos {
        bottom: 25px;
        padding: 0 13px;
        display: block;
    }

    .mod-iconos div {
        margin-right: 5px;
    }

    /* facil */
    .facil {
        width: 1.5cm;
    }

    /* minutos */
    .min {
        width: 1.15cm;
    }

    /* tiempo */
    .tiempo {
        width: 1cm;
    }

    /* preparacion */
    .prep {
        width: 1.85cm;
        margin-right: 0 !important;
    }

    .mod-iconos i {
        width: 24px !important;
        height: 24px !important;
    }
    .mod-iconos span {
        font-size: 7.88pt !important;
    }

    /* linea */
    #intro .linea {
        height: 5px;
    }

    /* mod */
    .mod {
        margin-bottom: 10px;
        padding-bottom: 5px;
    }

    /* preparacion */
    .mod-preparacion section {
        margin-bottom: 6px;
    }

    .mod-preparacion p {
        padding-left: 0;
    }

    /* etiqueta */
    .etiqueta {
        padding: 3px 5px !important;
        font-size: 10pt;
        max-height: 26px;
        overflow: hidden;
        display: flex;
        align-items: center;
        border-radius: 5px;
        margin-bottom: 5px;
        margin-right: 5px;
    }
    .mod-etiquetas {
        padding-bottom: 10px !important;
    }

    /* ingredientes - valores */
    .mod-ingredientes,
    .mod-valores {
        padding: 15px 20px;
    }

    .mod-ingredientes p {
        margin-bottom: 5px;
        font-weight: 400;
    }

    .mod-valores th,
    .mod-valores td {
        padding: 4px 0;
        font-weight: 400;
        border-bottom: 1px solid #eee !important;
    }
    .mod-valores th {
        font-size: 9pt;
        font-weight: 500;
    }
    .mod-valores td {
        font-size: 9.5pt;
        font-weight: 400;
    }

    .mod-valores h6 {
        font-size: 8pt;
        margin-top: 0;
    }

    .mod-acces {
        padding-bottom: 0 !important;
        page-break-before: always;
    }
}
